import {
  CurrencyTokenType,
  DownloadInfo,
  noForwardProps,
  useAccountContext
} from '@apeiron/library'
import { Box, styled } from '@mui/material'
import LootRushPNG from '@public/icons/icon-lootrush-square.png'
import Background2JPG from '@public/images/background-download-2.png'
import AdBannerSection from '@src/components/battle/demo/download/AdBannerSection'
import AdCardSection from '@src/components/battle/demo/download/AdCardSection'
import DownloadSection from '@src/components/battle/demo/download/DownloadSection'
import BattleDemoSecNavBar from '@src/components/navigationBar/secondary/BattleDemoSecNavBar'
import ApeironGotoRoundButton from '@src/components/share/apeiron/ApeironGotoRoundButton'
import ENV_CONFIG from '@src/config'
import { BattleDemoAdType } from '@src/constants/battleDemo'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { ROUTE } from '@src/constants/route'
import { useSecondaryNavBarContext } from '@src/contexts/share/SecondaryNavBarContext'
import { useCurrencyContext } from '@src/contexts/wallet/CurrencyContext'
import useFetchBattleDemoAdvertisementJson from '@src/hooks/json/useFetchBattleDemoAdvertisementJson'
import useFetchBattleDemoConfigJson from '@src/hooks/json/useFetchBattleDemoConfigJson'
import { BattleDemoAdSection } from '@src/types/battleDemo'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import * as R from 'ramda'
import * as RA from 'ramda-adjunct'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAsyncEffect from 'use-async-effect'

const Container = styled(Box)`
  background-position: center;
  background-size: cover;
  height: calc(100vh - 116px);
  width: 100%;
  position: relative;
  background-color: #12151c;
`

const BackgroundContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  position: relative;
  width: 100%;
`

const GotoDailyCheckIn = styled(
  ApeironGotoRoundButton,
  noForwardProps
)<GotoProps>`
  position: fixed;
  ${props => props.theme.breakpoints.up('res1024')} {
    right: 30px;
    top: calc(134px - ${props => (props.__noSubbar ? 56 : 0)}px);
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    right: 12px;
    top: calc(124px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      padding: 6px 10px;
      .Custom-Link {
        background-color: #000000aa;
      }
      .Custom-Icon-Text {
        gap: 4px;
        .Custom-Icon {
          width: 48px;
          margin: 0px 0px 0px 7px;
        }
        .Custom-Text {
          font-size: 14px;
          line-height: 18px;
          max-width: 100px;
        }
      }
    }
  }
`

const GotoLootRush = styled(GotoDailyCheckIn, noForwardProps)<GotoProps>`
  ${props => props.theme.breakpoints.up('res1024')} {
    top: calc(214px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      .Custom-Icon-Text {
        gap: 14px;
        .Custom-Icon {
          width: 50px;
          margin: 10px 0px 10px 8px;
        }
        .Custom-Text {
          font-size: 16px;
          max-width: 130px;
        }
      }
    }
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    top: calc(184px - ${props => (props.__noSubbar ? 56 : 0)}px);
    .Custom-Container {
      .Custom-Icon-Text {
        gap: 10px;
        .Custom-Icon {
          width: 36px;
          margin: 8px 0px 8px 12px;
        }
      }
    }
  }
`

const BackgroundImage2 = styled(Box)`
  width: 100%;
  aspect-ratio: 1918/395;
  position: sticky;
  background-image: url(${Background2JPG.src});
  background-size: cover;
  top: 0;
  margin-bottom: calc(-100% / 4.855 - 100px);
`

const DownloadClientPage: NextPage<Props> = props => {
  const { className, homeMode = false } = props

  const { query } = useRouter()

  const { promptLogin } = query

  const { isLoggedIn, setShowLoginPopup } = useAccountContext()

  const { setupBar } = useSecondaryNavBarContext()

  const { getCurrency } = useCurrencyContext()

  const { fetchJson: fetchConfigJson } = useFetchBattleDemoConfigJson()

  const { fetchJson: fetchAdvertiseJson } =
    useFetchBattleDemoAdvertisementJson()

  const { t } = useTranslation()

  const currency = getCurrency(CurrencyTokenType.Stardust)

  const [downloadInfoList, setDownloadInfoList] = useState<DownloadInfo[]>([])

  const [adSections, setAdSections] = useState<BattleDemoAdSection[]>([])

  useAsyncEffect(async (isActive: () => boolean) => {
    const result = await fetchConfigJson()

    if (!isActive()) {
      return
    }

    if (R.isNotNil(result)) {
      setDownloadInfoList(result.downloadMap.battleDemo)
    }
  }, [])

  useAsyncEffect(async (isActive: () => boolean) => {
    const result = await fetchAdvertiseJson()

    if (!isActive()) {
      return
    }

    setAdSections(result)
  }, [])

  useEffect(() => {
    if (promptLogin && !isLoggedIn) {
      setShowLoginPopup(true)
    }
  }, [isLoggedIn, promptLogin, setShowLoginPopup])

  useEffect(() => {
    if (!homeMode) {
      setupBar(
        <BattleDemoSecNavBar route={ROUTE.BATTLE_DEMO_DOWNLOAD_CLIENT} />
      )
    }

    return () => setupBar(null)
  }, [setupBar, homeMode])

  return (
    <Container className={className}>
      <BackgroundContainer>
        <DownloadSection infoList={downloadInfoList} showTitle={!homeMode} />
      </BackgroundContainer>
      <BackgroundContainer>
        <BackgroundImage2 />
        {RA.mapIndexed((adSection: BattleDemoAdSection, index: number) => {
          const firstAd = index === 0

          switch (adSection.type) {
            case BattleDemoAdType.Banner:
              return (
                <AdBannerSection
                  key={index}
                  adSection={adSection}
                  firstAd={firstAd}
                />
              )

            case BattleDemoAdType.Card:
              return (
                <AdCardSection
                  key={index}
                  adSection={adSection}
                  firstAd={firstAd}
                />
              )

            default:
              return false
          }
        }, adSections)}
      </BackgroundContainer>
      <GotoDailyCheckIn
        __noSubbar={homeMode}
        icon={currency?.image || ''}
        url={ROUTE.REWARD_DAILY_CHECK_IN}
        text={t('battle_demo.start_check_in')}
      />
      {featureAllowed(FeatureFlagType.ShowLootRush) && (
        <GotoLootRush
          __noSubbar={homeMode}
          icon={LootRushPNG.src}
          url={ENV_CONFIG.EXTERNAL_URL.LOOTRUSH.APEIRON}
          target='_blank'
          text={t('battle_demo.go_to_lootrush')}
        />
      )}
    </Container>
  )
}

type GotoProps = {
  __noSubbar: boolean
}

type Props = {
  className?: string
  homeMode?: boolean
}

export default DownloadClientPage
